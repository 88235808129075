import React from 'react'
// Types
import { TestimonialProps, HubspotForm as HubspotFormType } from '../../../types/items'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Heading } from '../../../atoms'
import { ContentMd } from '../../commons'
import { TestimonialSmall } from '../../testimonials'
import HubspotForm from '../../commons/HubspotForm'

interface ContactViewProps {
  headline: string
  content: string
  testimonial: TestimonialProps
  hubspotForm?: HubspotFormType
}

const ContactView: React.FC<ContactViewProps> = ({ headline, content, testimonial, hubspotForm }) => {
  return (
    <Grid>
      <Row between={'xs'}>
        <Col xs={12} sm={6}>
          <Box>
            <Heading fontSize={['28px', '34px', '40px']} lineHeight={['34px', '40px', '48px']}>
              {headline}
            </Heading>
            {!!content && (
              <Box mt={4}>
                <ContentMd content={content} />
              </Box>
            )}

            {testimonial && (
              <Box mt={[6, 7, 8]}>
                <TestimonialSmall {...testimonial} />
              </Box>
            )}
          </Box>
        </Col>
        <Col xs={12} sm={6} md={5}>
          <Box mt={[7, 0]}>
            {!!hubspotForm && (
              <HubspotForm region={hubspotForm.region} portalId={hubspotForm.portalId} formId={hubspotForm.formId} />
            )}
          </Box>
        </Col>
      </Row>
    </Grid>
  )
}

export default ContactView
