import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageInfoProps, PublicationProps } from '../types/pages'
import { SectionBase } from '../types/sections'
import { HubspotForm, TestimonialProps } from '../types/items'
// Components
import { Box } from '../atoms'
import { Layout, Seo } from '../components/commons'
import { ContactView } from '../components/contact'
import Section from '../components/sections'

interface DataQuery {
  datoCmsContact: {
    locale: string
    pageInfo: PageInfoProps
    meta: PublicationProps
    headline: string
    content: string
    testimonial: TestimonialProps
    hubspotForm?: HubspotForm
    sections: SectionBase[]
  }
}

const Contact: React.FC<PageProps<DataQuery>> = ({ data }) => {
  const {
    datoCmsContact: { locale, meta, sections, pageInfo, ...contactData },
  } = data

  return (
    <Layout headerDark={pageInfo.headerDark}>
      <Seo slug={pageInfo.slug} publication={meta} lang={locale} content={pageInfo.seo} />

      <Box pt={[120, 120, 160]}>
        <ContactView {...contactData} />
      </Box>

      {sections.map((s: SectionBase) => s?.model && <Section key={s.id} sectionApiKey={s.model.apiKey} data={s} />)}
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery($id: String!) {
    datoCmsContact(id: { eq: $id }) {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      headline
      content
      testimonial {
        ...testimonialFragment
      }
      hubspotForm {
        ...hubspotForm
      }
      sections {
        ...sectionFeatureSimpleFragment
        ...sectionLogoWallFragment
        ...sectionPodcastFragment
        ...sectionVideoFragment
      }
    }
  }
`

export default Contact
